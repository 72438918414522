import { PageRoutes } from 'constants/Routes';
import { AppContext } from 'context/AppContext';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { getLogger } from 'utils/logging';

const OverviewRedirect = () => {
  const router = useRouter();
  const { businessSubdomain, producerId } = useContext(AppContext);

  useEffect(() => {
    const log = getLogger('OverviewRedirect.useEffect');

    if (producerId) {
      if (businessSubdomain) {
        log.debug('Redirecting to business overview page');
        router.replace(`/business/${businessSubdomain}/overview`);
      } else {
        log.debug('Redirecting to onboarding page');
        router.replace(PageRoutes.ONBOARDING);
      }
    }
  }, [businessSubdomain, producerId]);

  return <></>;
};

export default OverviewRedirect;
